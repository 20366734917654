
import { IAccordion } from "@/models/accordion.model";

export default {
  data: (): IAccordion => {
    return {
      isOpen: false,
    };
  },
  name: "Accordion",
  props: ["title"],
};
