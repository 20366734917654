
import services from "@/services/index";
import * as roles from "../../services/mocks/filtersRoles.json";
import * as locales from "../../services/mocks/filtersLocales.json";
import { computed, defineComponent, reactive } from "vue";
import {
  cleanCurrentCandidates,
  setCurrentCandidates,
  setInfoCandidateSelected,
} from "@/store/candidates";
import { setCurrentLocale } from "@/store/locales";
import { setCurrentRole } from "@/store/roles";
import useStore from "@/hooks/useStore";

export default defineComponent({
  props: ["candidates"],
  data() {
    return {
      location: false,
      roleCandidates: "",
      localeCandidates: "",
      dataInfoCandidates: [],
      dataNameCandidates: [],
      showListCandidates: false,
      searchListCandidates: {
        name: "",
        id: "",
      },
    };
  },
  setup() {
    const store = useStore();
    const listCandidates = [];
    const data = reactive({
      roles: roles.data,
      locales: locales.data,
    });
    cleanCurrentCandidates();

    let currentRole = computed(function () {
      return store.Role.currentRole;
    });

    let currentCandidates = computed(function () {
      return store.Candidates.currentCandidates.objects;
    });

    let currentLocale = computed(function () {
      return store.Locale.currentLocale;
    });

    return {
      data,
      store,
      listCandidates,
      currentRole,
      currentCandidates,
      currentLocale,
    };
  },
  methods: {
    hasLocation(e: any) {
      if (
        !(
          e.target.value === "presidente" ||
          e.target.value === "deputado-distrital"
        )
      ) {
        this.location = true;
      } else {
        this.location = false;

        e.target.value === "presidente"
          ? (this.localeCandidates = "br")
          : (this.localeCandidates = "df");
      }
    },

    async getCandidates(): Promise<any> {
      try {
        const { data } = await services.dataCandidates.candidatesList(
          2022,
          this.localeCandidates,
          this.roleCandidates
        );
        setCurrentCandidates(data);
        setCurrentLocale(this.localeCandidates);
        setCurrentRole(this.roleCandidates);
      } catch (error) {
        console.log("Erro no carregamento de candidatos", error);
      }
    },

    handleSubmitCompareCandidates() {
      this.getCandidates();
      this.$router.push({
        name: "CandidateList",
        params: {
          year: 2022,
          locale: this.localeCandidates,
          role: this.roleCandidates,
        },
      });
    },

    async handleSearchCandidate(idCandidate) {
      try {
        const { dataCandidate } = await services.dataCandidates.candidate(
          idCandidate
        );
        this.getCandidates();
        setInfoCandidateSelected(dataCandidate);

        this.$router.push({
          name: "Candidate",
          params: {
            year: 2022,
            locale: this.localeCandidates,
            role: this.roleCandidates,
            keyCandidate: idCandidate,
          },
        });
      } catch (error) {
        console.log("Erro no carregamento da pessoa candidata", error);
      }
    },
  },
  computed: {
    filteredList() {
      this.getCandidates();
      return this.store.Candidates.currentCandidates.objects?.filter(
        (candidates) => {
          return candidates.name
            .toLowerCase()
            .includes(this.searchListCandidates.name.toLowerCase());
        }
      );
    },
  },
});
