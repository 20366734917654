
import Footer from "@/components/Footer.vue";
import Accordion from "./Accordion.vue";
import FormSearchCandidate from "./FormSearchCandidate.vue";
import IconPP from "../../components/IconPP.vue";

export default {
  components: {
    Footer,
    Accordion,
    FormSearchCandidate,
    IconPP,
  },
};
